.landing-page-body {
    font-family: "Open Sans", sans-serif;
    hr {
        height: 0.5px !important;
        background-color: lightslategray;
        border: none !important;
        color: lightslategray;
        margin-bottom: 0;
        // margin-top: 2rem;
    }
    p {
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: Arial, Helvetica, sans-serif;
        color: #516f90;
    }
    .help-cards {
        h5 {
            font-weight: bold;
            color: #2d3e50;
        }
        a {
            text-decoration: none;
            color: #484848;
        }
    }
    .help-cards-box {
        padding: 10px;
        height: 100%;
        border-radius: 10px;
        // background-color: white;
        // border: 1px solid red;
    }
}
