.articles-comp-styles {
    font-family: "Open Sans", sans-serif;
    a {
        color: #d23f99;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
    .list-group-item:hover {
        background-color: lightblue;
    }
    .breadcrumb {
        background-color: white;
        font-size: 0.75rem;
        margin-bottom: 0 !important;
        padding: 0;
        // a {
        //     color: #d23f99;
        //     text-decoration: none;
        // }
        // a:hover {
        //     text-decoration: underline;
        // }
    }
    .breadcrumb-item + .breadcrumb-item::before {
        content: "\203A";
        font-size: 1.75rem;
        position: relative;
        top: -14px;
        color: #d23f99;
    }
    background-color: #f5f8fa;
    // background-color: #f4f2f3;s
    .custom-fixed-sideBar {
        position: fixed;
        top: 100px;
        width: 250px;
        height: 60vh;
        overflow-y: auto;
        scrollbar-width: none;
        // z-index: 100;
    }
    .side-list-items-styles {
        // .css-10hburv-MuiTypography-root {
        .list-item-text {
            color: #484848 !important;
            font-size: 0.875rem !important;
            font-family: "Open Sans", sans-serif !important;
        }
        a:hover {
            text-decoration: none;
        }
    }
    .mob-list-collapse {
        ul {
            list-style: none;
            padding: 0;
            padding: 2.45rem 1.25rem 3.1875rem;

            .main_topic {
                padding-bottom: 0.3rem;
                margin-bottom: 1.5rem;
                a {
                    color: #484848;
                    font-weight: 600;
                    font-size: 1.5rem;
                    line-height: 1.75rem;
                }
            }
            .sub_topic {
                margin-top: 1.1rem;
                margin-bottom: 1rem;
                padding: 0;
            }
            .sub_topic_item {
                cursor: pointer;
                a {
                    font-size: 1rem;
                    font-weight: normal;
                }
            }
        }
    }
    .sub-topics {
        // .css-10hburv-MuiTypography-root {
        .collapse-list-item {
            font-size: 0.75rem;
            color: #181818;
            font-weight: normal;
        }
        // a:hover{
        //     text-decoration: none !important;
        // }
    }
    .css-tlelie-MuiListItemText-root {
        margin: 0;
    }
    // .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root{
    //     padding: .75rem;
    // }

    // .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root:hover {
    //     background-color: #e3bdd4 !important;
    // }
    .list-item-hover:hover {
        background-color: #fbc6e3 !important;
        // background-color: #e3bdd4 !important;
    }

    ::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
    }
    .content-body-section {
        // font-size: 0.875rem ;

        // ----scrolling----
        // height: 500px;
        // overflow-y: auto;
        // scrollbar-width: none;

        .go-back-link {
            color: #d23f99;
            font-size: 0.875rem;
            font-weight: normal;
            cursor: pointer;
        }
        .go-back-link:hover {
            text-decoration: underline;
        }
        h2 {
            color: #484848;
            margin-bottom: 0.375rem;
            font-weight: 500;
        }
        h6 {
            color: #181818;
            font-family: "Roboto", sans-serif;
            margin-top: 0.375rem;
            font-size: 0.875rem;
            text-transform: none;
            font-weight: normal;
            letter-spacing: 1px;
        }
        .desc,
        .section-items {
            padding-bottom: 1.3125rem;
            margin-bottom: 1.75rem;
            margin-top: 0.375rem;
            border-bottom: 1px solid #dfe3eb;
        }
        section:not(:last-child) {
            border-bottom: 1px solid #dfe3eb;
        }
        section {
            margin-bottom: 1.75rem;
            padding-bottom: 1rem;
            div {
                padding-bottom: 0.75rem;
            }
        }
        a {
            // font-family: "Roboto", sans-serif;
            font-size: 0.875rem;
        }
        h4 {
            margin-top: 1.75rem;
            font-size: 18px;
            font-weight: 600;
            color: #484848;
        }

        // content-body-styling
        .content-body {
            iframe {
                width: 100%;
            }
            strong {
                color: #181818;
            }
            h1 {
                font-size: 2rem !important;
                font-weight: 600;
                color: #484848;
                strong {
                    font-size: 2rem !important;
                }
            }
            h2 {
                font-size: 1.375rem !important;
                line-height: 1.875rem;
                margin-top: 2.5rem;
                color: #484848;
                strong {
                    font-size: 1.375rem !important;
                }
            }
            h3 {
                font-size: 1.375rem !important;
                font-weight: 600;
                line-height: 1.875rem;
                color: #484848;
                strong {
                    font-size: 1.375rem !important;
                }
            }

            margin-top: 25px;
            p {
                // font-size: 1rem !important;
                font-size: 14px !important;
                font-weight: normal;
                color: #181818;
                margin: 0.5rem 0 0.625rem 0;
                strong {
                    font-size: 14px;
                    // font-size: 1rem;
                }
                a {
                    // font-size: 1rem;
                    font-size: 14px;
                }
            }
            ul {
                li {
                    font-size: 14px !important;
                    // font-size: 1rem !important;
                    strong {
                        // font-size: 1rem;
                        font-size: 14px;
                    }
                }
            }
            // p {
            //     font-size: 0.875rem;
            //     font-weight: normal;
            //     color: #181818;
            // }
        }
        .rating-btns {
            button {
                width: 90px;
                height: 32px;
                border-radius: 3px;
                border: 1px solid #d23f99;
            }
        }
        .related-div {
            h3 {
                margin-top: 50px;
            }
            ul {
                padding-left: 0.5rem;
                list-style-type: none;
                li {
                    margin-top: 1rem;
                }
            }
        }

        // see more links
        .list-toggle {
            cursor: pointer;
            color: grey;
            font-size: 14px;
            font-weight: normal;
        }
        .list-toggle:hover {
            text-decoration: underline;
        }
    }

    @media (max-width: 992px) {
        .content-body-section {
            overflow-y: unset;
        }
    }
}
@media (min-width: 1400px) {
    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
}
