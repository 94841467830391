::selection {
    color: #fff;
    background: #d23f99;
}
.input-group-text {
    border-radius: 0px;
}

.form-control {
    border-radius: 0px;
    background-color: #fff;
    padding: 10px 14px;
    font-size: 16px;
}
.form-control:focus {
    border: 1px solid #e3e3e3;
}

.navwth100 {
    width: 100%;
}

.bg-info {
    /* background-color: #d23f99 !important; */
    background-color: #4e5160 !important;
}

.page-header .content-center {
    bottom: 0;
    top: unset;
}
.custom-fixed-header {
    position: fixed;
    width: 100%;
    z-index: 100;
    min-height: 70px !important;
    height: 80px !important;
    // min-height: 150px !important;
    // height: 150px !important;
}
// .page-header-small {
//     // min-height: 350px !important;
//     min-height: 400px !important;
// }
.page-header::before {
    background-color: transparent;
}

.navbar.no-bs {
    box-shadow: none;
}
.navbar .navbar-nav .nav-link:not(.btn) {
    text-transform: none;
    font-size: 14px;
}
.navbar-toggler:focus {
    box-shadow: none;
}
.navbar-brand-logo {
    width: 100%;
}
.search-results li:hover {
    background-color: #fbc6e3;
    // background-color: #e3bdd4;
}
@media (max-width: 768px) {
    .search-results-mob {
        // width: 516px !important;
        // top: 125% !important;
        padding-inline: 12px;
    }
}
@media (max-width: 992px) {
    .navbar-brand-logo {
        width: 100%;
    }
    // .sidebar-collapse .navbar .navbar-toggler-bar {
    //     height: 2px;
    // }
    .sidebar-collapse .navbar .navbar-toggler-bar {
        height: 2px;
    }
    .sidebar-collapse .navbar-collapse::before {
        background: white;
    }
    .sidebar-collapse
        .navbar-collapse
        .navbar-nav:not(.navbar-logo)
        .nav-link:not(.btn) {
        color: black !important;
        font-size: 16px;
        border-bottom: 0.5px solid lightslategray;
    }
    .black-bars {
        background: #000 !important;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1320px !important;
    }
}

body {
    top: 0px !important;
}

body > .skiptranslate {
    display: none;
}

#goog-gt-tt, .goog-te-balloon-frame,div#goog-gt-{display: none !important;}
  
.goog-text-highlight { background: none !important; box-shadow: none !important;}